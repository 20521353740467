import base from '../base.js'  //导入接口域名列表
import axios from '../http.js'; //导入http中创建的axios实例
import {cookieUtil} from '../../until/until.js'
const client = {
	login(params){  //登录
		return axios.post(`/users/login`,params,{headers:{'Content-Type': 'multipart/form-data'}})
		// return axios.post(`${base.entry}/api/v1/client/tagsCount`,params)
	},

	usersig(params){  // 验签
		let token = cookieUtil.getCookie('token')
		return axios.post(`/live/tencentcloud/usersig?token=${token}`, {...params,sdkappid:process.env.VUE_APP_APPID})
		// return axios.post(`${base.entry}/api/v1/client/tagsCount`,params)
	},

	apply(params){  //获取播放教室
		let token = cookieUtil.getCookie('token')
		return axios.post(`/live/stream/apply?token=${token}`, {...params,platform:4,live_type:3})
		// return axios.post(`${base.entry}/api/v1/client/tagsCount`,params)
	},

	getProgress(params){
		let token = cookieUtil.getCookie('token')
		return axios.post(`/api/v4/vote/progress`,{...params})
	}

	// https://api.xroom.net/v1/live/tencentcloud/usersig?token=07bc88f588620b0bdf8e97abd660aa713665d054


	// {{api}}/v1/live/stream/apply?token=07bc88f588620b0bdf8e97abd660aa713665d054

}

export default client

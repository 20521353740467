<template>
	<div class="loading" v-if="$store.state.loading">
		<cube-loading :size="40"></cube-loading>
	</div>
</template>

<script>
export default {
	
}
</script>

<style scoped lang="less">
.loading{
	background: #fff;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999999;
}
</style>

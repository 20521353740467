import Vue from 'vue'
import './cube-ui'
import './assets/css/iconfont.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './until/until.js'
import 'amfe-flexible'
import '@/common/css/reset.css'
import api from '@/request/index.js'  //导入api接口
import tool from '@/common/js/tool.js'  //公共js
import loading from '@/components/popup/loading.vue'  //loading动画
import onlyLoading from '@/components/popup/onlyLoading.vue'  //onlyLoading动画
import axios from 'axios'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$axios = axios
import Cube from 'cube-ui'
Vue.use(Cube)
// import e from './until/TXLivePlayer-0.0.4-beta.min.js'
import TRTC from 'trtc-js-sdk';
Vue.prototype.$TRTC = TRTC;

import Vconsole from 'vconsole'
// 初始化 SDK 实例
if(process.env.NODE_ENV === 'development'){
  new Vconsole()
}

Vue.prototype.$api = api
Vue.prototype.$tool = tool
Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。

Vue.config.productionTip = false

Vue.component('loading',loading)
Vue.component('onlyLoading',onlyLoading)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <router-view/>
	<loading></loading>
	<onlyLoading></onlyLoading>
  </div>
</template>
<script>


export default {
  name:'App',
  mounted(){
    window.onload=function () {
        document.addEventListener('touchstart',function (event) {
          if(event.touches.length>1){
            event.preventDefault();
          }
        });
        var lastTouchEnd=0;
        document.addEventListener('touchend',function (event) {
          var now=(new Date()).getTime();
          if(now-lastTouchEnd<=300){
            event.preventDefault();
          }
          lastTouchEnd=now;
        },false);
        document.addEventListener('gesturestart', function (event) {
          event.preventDefault();
        });
      }
  }
}
</script>

<style scoped>
#app{
  height: 100%;
  overflow: hidden;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
body{
  overflow: hidden;
}
</style>
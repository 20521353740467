<template>
	<div class="detail">详情</div>
</template>

<script>
export default {
	
}
</script>

<style scoped lang="less">
.detail{
	width: 20px;
}
</style>

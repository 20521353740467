import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from '@/request/http.js'  //导入http中创建的axios实例
//路由地址
import home from '@/views/home/index'
import login from '@/views/login/login'
import detail from '@/views/detail/detail'
import {getQueryString, cookieUtil} from "../until/until"
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: home,
		meta: {
			title: '首页'
		}
	  },
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			title: '登录'
		}
	  },
  
  
//   {
//     path: '/detail',
//     name: 'detail',
//     component: detail,
// 	meta: {
// 		title: '详情'
// 	}
//   },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next) => {
	if(to.meta.title){  //如果设置标题，拦截后设置标题
		// document.title = to.meta.title
	}
	if(localStorage.getItem('userId') && cookieUtil.getCookie('token')){  //已有登录信息
		// ucLoginPost(store.state.projectUrl + to.fullPath)  //校验uc_token是否失效
		next()
	} else if(to.fullPath.includes('login')){
		next()

	} else{  //未登录
		// if(form)
		if(sessionStorage.getItem('activity_id')){
			next()

		}else{
			console.log(getQueryString('activity_id'))
			sessionStorage.setItem('activity_id', getQueryString('activity_id'))
			// window.location.href='/'
			router.push({path:'/login',query:{activity_id: sessionStorage.getItem('activity_id')}})
			next()
		}
		
		
	}
})

export default router

import axios from 'axios'
// import {cookieUtil} from '../until/until.js'
// axios.defaults.baseURL = 'https://api.xroom.net'
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

const http = axios.create({
	timeout: 30 * 1000,  //超时时间设置
	// baseURL: 'https://api.xroom.net'
});

http.interceptors.request.use(config => {
	if(!(config.headers["Content-Type"] && config.headers["Content-Type"].includes('form-data'))){
		config.data = JSON.stringify(config.data);  //数据转化
	}
	return config
},error => {
	Promise.reject(error)
})

http.interceptors.response.use(response => {
	if(response.status == '200'){
		return Promise.resolve(response.data);
	}else{            
		return Promise.reject(response);
	}
},error => {
	return Promise.reject(error);
});

export default http
<template>
  <div class="video-call-section">
        <!-- <div id="remote" style="width:200px; height:200px;"></div>  -->
        <div id="remote_stream" style="">
        </div>
        <div class="button-box" v-show="showButton">
          <div @click="muteVideo">
            <span class="iconfont icon-shipin" :style="{color: video? '#fff':'red',}"></span>
          </div>
          <div @click="muteAudio">
            <span class="iconfont icon-yuyin" :style="{color: audio? '#fff':'red'}"></span>
          </div>
          <div>
            <span class="iconfont icon-toupiao-m" @click="vote"></span>
          </div>
        </div>
        <vote ref="votePage" :getVote="getVote" :voteId="voteId" @clearVoteId="clearVoteId"></vote>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from "vuex";
import { getQueryString} from '../../until/until'
import vote from '../../components/vote/vote'
export default {
  name: "home",
    components:{
        vote
    },
  computed: {
    ...mapState({
      APPInfo:'APPInfo',
      userInfo:'userInfo'
    })
  },
  data() {
    return {
      showButton: false,
      isShowVideoCall: false,
      isVideoOn: true,
      isAudioOn: true,
      userId2Name: {},
      localStream:'',
      voteId:[],
      audio:true,
      video: true,
      interval:''
    };
  },
   mounted() {
    let that = this;
    if(!this.APPInfo.SDKAppID || !this.userInfo.uid || !this.APPInfo.usersig ){
      const toast = that.$createToast({
                time: 1500,
                txt: '登录信息超时请重新登录'
              })
              toast.show()
              setTimeout(() =>{
                 this.$router.push({path:'/login',query:{activity_id: getQueryString('activity_id')}})
                // this.$router.push({path:'/login',})
              },1500)
      return
    }
   Vue.prototype.$client = this.$TRTC.createClient({ mode: 'rtc', sdkAppId : this.APPInfo.SDKAppID, userId:this.userInfo.uid, userSig: this.APPInfo.usersig });
     this.localStream = this.$TRTC.createStream({ userId:this.userInfo.uid, audio: true, video: true });
     if(!getQueryString('activity_id')){
      const toast = that.$createToast({
              time: 1000,
              txt: '未查询到活动信息'
            })
        toast.show()
        return
     }
     this.$api.apply({ "activity_id": getQueryString('activity_id') }).then((res) =>{
        if(res.errcode === 0){
        that.$client.join({ roomId: Number(res.data.live_channel)  }).then(() => {
        that.localStream.initialize().then(() => {
          // box  remote_stream
            that.showButton = true;
            that.localStream.play('remote_stream').then(() => {
            // autoplay success
            }).catch((error) => {
            const errorCode = error.getCode();
            if (errorCode === 0x4043) {
                // PLAY_NOT_ALLOWED,引导用户手势操作并调用 stream.resume 恢复音视频播放
                // stream.resume()
            }
            });
        // 本地流初始化成功，发布本地流
        that.$client.publish(that.localStream).then((res) => {
            let id = that.localStream.getId();
            let html =document.createElement("p");
                html.innerHTML=that.localStream.userId_;
                html.style.position='absolute'
                html.style.bottom='0'
                html.style.color='#fff'
                html.style.zIndex='2'
                html.style.width='100%'
                html.style.height='20px'
                html.style.paddingLeft='5px'
                html.style.left='0'
                let dom = document.querySelector('#player_' + id);
        dom.style.width = '50%'
        dom.style.height = '50%'
         dom.appendChild(html)
        // 本地流发布成功
        })
        }).catch((error) => {
        // 本地流初始化失败
        var strs = '';
        switch (error.name) {
            case 'NotFoundError':
              strs = '找不到满足请求参数的媒体类型';
            // 提示用户：找不到满足请求参数的媒体类型（包括：音频、视频、屏幕分享）。例如：PC 没有摄像头，但是请求浏览器获取视频流，则会报此错误。
            break;
            case 'NotAllowedError':
              strs = '不授权摄像头/麦克风访问无法进行音视频通话';

            // 提示用户：不授权摄像头/麦克风访问无法进行音视频通话
            break;
            case 'NotReadableError':
              strs = '请确保当前没有其他应用请求访问摄像头/麦克风';

            // 提示用户：暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试。
            break;
            case 'OverConstrainedError':
              strs = '确保 cameraId/microphoneId 传值正确且有效';

            // 提示用户：确保 cameraId/microphoneId 传值正确且有效
            break;
            default:
            console.error(error);
              strs = '由于某些未知原因导致设备无法被使用';

            // 由于某些未知原因导致设备无法被使用
            break;
        }
        const toast = that.$createToast({
                time: 1500,
                txt: strs
              })
              toast.show()
        })

    }).catch(error => {
    console.error('Join room failed: ' + error);
    });
    } else{
      const toast = that.$createToast({
        time: 1000,
        txt: res.errmsg
      })
      toast.show()
    }
     });


  that.$client.on('stream-added', event => {
    const remoteStream = event.stream;
    console.log('进入',remoteStream)
    let id = remoteStream.getId();
    that.$client.subscribe(remoteStream, { audio: true, video: true }).catch(e => {
      console.error('failed to subscribe remoteStream',e);
    });
      console.log('#player_' + id)
    let html =document.createElement("p");
    html.innerHTML=remoteStream.userId_;
    // html.className="name"
    html.style.position='absolute'
    html.style.bottom='0'
    html.style.color='#fff'
    html.style.zIndex='2'
    html.style.width='100%'
    html.style.height='20px'
    html.style.paddingLeft='5px'
    html.style.left='0'
    let dom = document.querySelector('#player_' + id);
        dom.style.width = '50%'
        dom.style.height = '50%'
         dom.appendChild(html)
  });
  // 监听‘stream-removed’事件
that.$client.on('stream-removed', event => {
  const remoteStream = event.stream;
  console.log('remoteStream ID: ' + remoteStream.getId() + ' has been removed');
  // 停止播放并删除相应<video>标签
  document.querySelector(`#player_${remoteStream.getId()}`).remove()
});

// 监听‘stream-updated’事件
that.$client.on('stream-updated', event => {
  const remoteStream = event.stream;
  console.log('remoteStream ID: ' + remoteStream.getId() + ' was updated hasAudio: '
              + remoteStream.hasAudio() + ' hasVideo: ' + remoteStream.hasVideo());
});


//  监听远端流
    that.watchstream()

    that.getVote()
  },
  destroyed() {
    clearInterval(this.interval)
  },

  methods: {
    watchstream(){
        this.$client.on('stream-subscribed', event => {
          const remoteStream = event.stream;
          // 远端流订阅成功，播放远端音视频流
          remoteStream.play('remote_stream');
        });
    },
    vote() {
        this.$refs.votePage.getPage(this.voteId)
    },
    clearVoteId() {
      this.voteId = []
    },
    // 轮询接口
    getVote(){
        // this.$refs.votePage.getPage()
      // clearInterval(this.interval)
      let that = this;
      this.interval = setInterval(async () =>{
                   let params = {
                       activity_id:getQueryString('activity_id'),
                       user_id:this.userInfo.uid
                   }
                  let res = await that.$api.getProgress(params)
                  if(res.errcode === 200){
                    this.voteId = res.data.vote_id;
                  }
                  // if(res.data.rest ===0){
                  //   clearInterval(this.interval)
                  // }

                    },10000)
    },
    //  开启关闭音频
    muteAudio(){
      let that = this;
       this.$createDialog({
        type: 'confirm',
        icon: 'cubeic-alert',
        title: this.audio ? '确定关闭语音吗？' : '确定开启语音吗？',
        // content: '我是内容',
        confirmBtn: {
          text: '确定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
            if( that.audio){
        that.localStream.muteAudio()
      }else{
        that.localStream.unmuteAudio()
      }
      that.audio = !that.audio;
        },
        onCancel: () => {

        }
      }).show()


    },
    //  开启关闭视频
    muteVideo(){
        this.$createDialog({
        type: 'confirm',
        icon: 'cubeic-alert',
        title: this.video ? '确定关闭视频吗？' : '确定开启视频吗？',
        // content: '我是内容',
        confirmBtn: {
          text: '确定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          if( this.video){
        this.localStream.muteVideo()
      }else{
        this.localStream.unmuteVideo()
      }
      this.video = !this.video;
        },
        onCancel: () => {

        }
      }).show()

    }

  }
};
</script>

<style scoped lang="less">
.video-call-section{
  height: 100%;
  -webkit-overflow-scrolling: touch; //ios卡顿
  overflow-y: scroll;
  top:0;
  width: 100%;
}
/* #remote_stream > div{
  width: 50%!important;
  height: 50%!important;
} */
#remote_stream{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
    >div{
      width: 50%!important;
      height: 50%!important;
    }
}

.button-box{
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: black;
  color: #fff;
  z-index: 22222;
  div{
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    height: 100%;
    // img{
    //   width: 50px;
    //   height: 50px;
    //   border-radius: 50%;
    // }
  }
}
</style>

import moment from 'moment'
const cookieUtil = {
	setCookie(c_name, value, expiremMinutes) {
		var exdate = new Date();
		exdate.setTime(exdate.getTime() + expiremMinutes * 60 * 1000);
		document.cookie =
			c_name +
			"=" +
			escape(value) +
			(expiremMinutes == null ? "" : ";expires=" + exdate.toGMTString());
	},
	getCookie(c_name) {
		if (document.cookie.length > 0) {
			var c_start = document.cookie.indexOf(c_name + "=");
			if (c_start != -1) {
				c_start = c_start + c_name.length + 1;
				var c_end = document.cookie.indexOf(";", c_start);
				if (c_end == -1) c_end = document.cookie.length;
				return unescape(document.cookie.substring(c_start, c_end));
			}
		}
		return "";
	},
	delCookie(c_name) {
		var exp = new Date();
		exp.setTime(exp.getTime() - 1);
		var cval = this.getCookie(c_name);
		if (cval != null) {
			document.cookie = c_name + "=" + cval + ";expires=" + exp.toGMTString();
		}
	},
};
const getQueryString = name => {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = window.location.href.replace('?', '&').substr(1).match(reg);
	if (r != null) {
		return decodeURIComponent(r[2]);
	}
	return "";
};
const getClear = arr => {
	let len = arr.length;
	for (let i = 0; i < len; i++) {
		if (!arr[i] || arr[i] == "" || arr[i] == "undefined") {
			arr.splice(i, 1);
			len--;
			i--;
		}
	}
	return arr;
};
const dealDate = (dates, falg) => {

	if (dates && dates != 'undefined' ) {
		if (typeof dates != 'number' && dates.indexOf('-') == -1) {
			dates = Number(dates)
		}
		dates = moment(dates);
		let date = new Date(dates);
		let y = date.getFullYear();
		let m = date.getMonth() + 1;
		m = m < 10 ? "0" + m : m;
		let d = date.getDate();
		d = d < 10 ? "0" + d : d;
		let h = date.getHours();
		h = h < 10 ? "0" + h : h;
		let fg = '';
		let minute = date.getMinutes();
		if (falg) {
			fg = y + "-" + m + "-" + d;
		} else {
			minute = minute < 10 ? "0" + minute : minute;
			let second = date.getSeconds();
			second = second < 10 ? "0" + second : second;
			fg = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
		}
		return fg;
	} else {
		return dates;
	}
};
const formatJson = (filterVal, jsonData) => {
	return jsonData.map(v =>
		filterVal.map(
			j => {
				if (j.split('.').length > 0) {
					j = j.split('.');
					j.map(k => {
						v = v[k]
					})
					return v;
				} else {
					return v[j]
				}
			}
		));
};
const deWeight = arrs => {
	let arr_sum = [];
	arrs.map(item => {
		arr_sum.push(item.code);
	});
	let newArr = [];
	let newArr2 = [];
	for (let i = 0; i < arr_sum.length; i++) {
		if (newArr.indexOf(arr_sum[i]) == -1) {
			newArr.push(arr_sum[i]);
			newArr2.push(arrs[i]);
		}
	}
	return newArr2;
};

const delBirdtry = sign => {
	sign = sign.replace(/^\s+|\s+$/g, "")
	let tmpStr = "";
	if ((sign.length != 15) && (sign.length != 18)) {
		return {
			flg: false,
			mes: "输入的身份证号位数错误",
			sex: ''
		};
	}
	if (sign.length == 15) {
		tmpStr = sign.substring(6, 12);
		tmpStr = "19" + tmpStr;
		tmpStr = tmpStr.substring(0, 4) + "-" + tmpStr.substring(4, 6) + "-" + tmpStr.substring(6)
	} else {
		tmpStr = sign.substring(6, 14);
		tmpStr = tmpStr.substring(0, 4) + "-" + tmpStr.substring(4, 6) + "-" + tmpStr.substring(6)
	}
	return {
		flg: true,
		mes: tmpStr,
		sex: Number(sign.charAt(sign.length - 2)) % 2 == 0 ? '2' : '1'
	}
}
/**
 *
 * @param params stream--二进制流
 * @param params type--Blob类型
 * @param params name--文件名称
 * @param params format--文件类型
 * @注意 默认为导出和下载pdf功能
 */
const exportFormat = params => {
	if (!params.format) {
		params.format = '.xls';
	}
	let downName = params.format ? params.name + dealDate(new Date().getTime()) + params.format : params.name + dealDate(new Date().getTime());
	switch (params.exportType) {
		case '1'://pdf预览
			let url = "data:application/pdf;base64," + params.stream;
			let pdfParams = JSON.stringify({
				url: url,
				name: params.name
			});
			sessionStorage.setItem("base64Url", encodeURIComponent(pdfParams));
			let url2 = "./PDFJSInNet/web/tongyishu.html";
			window.open(url2);
			break;
		default:
			let link = document.createElement('a');
			let blob = new Blob([params.stream], { type: params.type || 'application/vnd.ms-excel;charset=utf-8' });
			link.href = URL.createObjectURL(blob);
			link.id = 'downOrExport';
			link.innerHTML = 'downOrExport';
			link.download = downName;
			link.setAttribute('download', downName);
			document.body.appendChild(link);
			if (navigator.msSaveBlob) {
				return navigator.msSaveBlob(blob, downName)
			} else {
				link.click();
			}
			URL.revokeObjectURL(link.href)
			document.body.removeChild(link);
			break;
	}
}
/**
 * 判断当前分辨率进行当前布局的设置
 */
const judgeSreen = () => {
	let span = 6;
	if (document.body.clientWidth >= 1660) {
		span = 6;
	} else {
		span = 8;
	}
	return span;
}

const judgeBrowerType = () => {
	let u = navigator.userAgent;
	return {
		trident: u.indexOf('Trident') > -1, //IE内核
		presto: u.indexOf('Presto') > -1, //opera内核
		webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
		gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
		mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
		ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
		android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
		iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
		iPad: u.indexOf('iPad') > -1, //是否iPad
		webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
		weixin: u.indexOf('MicroMessenger') > -1, //是否微信
		qq: u.match(/\sQQ/i) == " qq" //是否QQ
	}
}

export {
	cookieUtil,
	getQueryString,
	getClear,
	dealDate,
	formatJson,
	deWeight,
	delBirdtry,
	exportFormat,
	judgeSreen,
	judgeBrowerType
};

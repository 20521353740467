<template>
    <cube-popup type="my-popup" ref="myPopup" >
        <div  class="scroll-list-wrap" style="width:90vw;padding:20px;background-color:#fff;">
            <cube-scroll ref="scroll" style="height:50vh;max-height:80vh; overflow:auto;position: relative">
                <div style="width:90vw;height:10px; text-align: right ;font-size: 24px" @click="close">
                    <i class="cubeic-close" ></i>
                </div>
                <div  v-for="(item,index) in voteList">
                    <p class="title"  style="margin-top:20px;"><i class="cubeic-alert"></i>{{voteList[index].active_title}}  </p>
                    <div  style="margin-top:20px;">
                        <cube-radio-group  v-model= "voteList[index].vote_option_id"  :options="voteList[index].radioList" />
                    </div>
                    <p class="title" style="margin-top:20px;">投票理由</p>
                    <cube-textarea style="margin-top:10px;" v-model="voteList[index].remark" placeholder="请输入投票理由"></cube-textarea>
                    <cube-checkbox style="margin-top:10px;" v-model="voteList[index].anonymous"  :disabled="voteList[index].isDisabled">
                        是否匿名
                    </cube-checkbox>
                    <hr align=center width=300 color=#eeeeee SIZE=1 />
                </div>
                <cube-button :primary="true" :disabled="issubmit" @click="submit">提交</cube-button>

            </cube-scroll>
        </div>

    </cube-popup>
</template>

<script>
import { mapState } from "vuex";

    export default {
        props:{
            getVote:{
                type: Function
            },
            voteId:{
                type: Array
            }
        },
        name: "vote.vue",
         computed: {
    ...mapState({
      APPInfo:'APPInfo',
      userInfo:'userInfo'
    })
  },
        data() {
            return{
                issubmit:false,
                active_title:"",
                radio:1,
                textarea:"",
                isDisabled:false,
                anonymous:false,
                voteList:[
                    // {
                    //     active_title:"第玩儿访问前方前锋武器服务器而访问前方尾崎丰王企鹅浮球阀一场投票",
                    //     radio:'',
                    //     radioList:[
                    //         {
                    //             label:"赞成大法师法王企鹅二请问父亲恶违法全文发请问无法全文废弃物发我去二分我完全无法企鹅无法蔷薇粉无法为",
                    //             value:1
                    //         },
                    //         {
                    //             label:"反对访问发完飞娃儿访问前方让我欺负让我欺负让我欺负让我欺负区文峰区文峰区违法王企鹅废弃物违反欠费请问",
                    //             value:2
                    //         },
                    //         {
                    //             label:"弃权微服务却无法违反全微分尾崎丰 尾崎丰王企鹅费全额付尾崎丰全微分违反去恶违法全文发企鹅服务器而无法为",
                    //             value:3
                    //         },
                    //     ],
                    //     textarea:"",
                    //     anonymous:false
                    // },
                    // {
                    //     active_title:"第二场投票",
                    //     radio:'',
                    //     radioList:[
                    //         {
                    //             label:"赞成2",
                    //             value:1
                    //         },
                    //         {
                    //             label:"反对2",
                    //             value:2
                    //         },
                    //         {
                    //             label:"弃权2",
                    //             value:3
                    //         },
                    //     ],
                    //     textarea:"",
                    //     anonymous:false
                    // },
                    // {
                    //     active_title:"第三场投票",
                    //     radio:'',
                    //     radioList:[
                    //         {
                    //             label:"赞成3",
                    //             value:1
                    //         },
                    //         {
                    //             label:"反对3",
                    //             value:2
                    //         },
                    //         {
                    //             label:"弃权3",
                    //             value:3
                    //         },
                    //     ],
                    //     textarea:"",
                    //     anonymous:false
                    // },
                ],
                radioList:[
                    // {
                    //     label:"赞成",
                    //     value:1
                    // },
                    // {
                    //     label:"反对",
                    //     value:2
                    // },
                    // {
                    //     label:"弃权",
                    //     value:3
                    // },
                ],
                activity_id: this.$route.query.activity_id,
                // vote_id :  ''

            }
        },
        mounted() {

        },
        methods:{
            close() {
                this.component.hide()
            },
            async getPage(vote_id) {
                this.voteList=[];
                if(vote_id.length == 0) {
                    this.toast = this.$createToast({
                        txt: '暂无投票信息',
                        type: 'txt'
                    })
                        this.toast.show()
                }
                for(let i = 0; i < vote_id.length; i++) {
                    this.getVoteList(vote_id[i])
                }
                this.component = this.$refs['myPopup']
                // this.component.show()

            },
            //获取投票内容
            getVoteList(vote_id) {
                const baseUrl = "https://api.xroom.net/v4"
                // this.active_title = "暂无投票内容"
                let radioListVal =[]
                // this.radioList =[]
                this.$axios.get('/api/v4/vote/get/'+vote_id).then((ress)=>{
                    console.log("获取投票列表", res)
                    let res = ress.data;
                    if(res.errcode === 200){
                        let data = ress.data.data;
                    // this.anonymousVal = data.anonymousVal;
                        this.isDisabled = data.anonymousVal == 0 ? true : false;
                        // this.anonymous = data.anonymousVal ? false : true;
                        this.active_title = data.vote_content
                        for(let i = 0; i < data.options.length; i++) {
                            radioListVal.push({
                                label:data.options[i].option_content,
                                value:data.options[i].id
                            })
                        }
                        let anonymousval = 0;
                        if(this.isDisabled == true) {
                            anonymousval = 0;
                        }else{
                            if(this.anonymous == true) {
                                anonymousval = 0
                            }else{
                                anonymousval = 1
                            }
                        }
                        let voteobj = {
                            active_title:this.active_title,
                            anonymous:false,
                            isDisabled:this.isDisabled,
                            radioList:radioListVal,
                            vote_option_id:'',
                            remark:'',
                            vote_id:vote_id,
                        }
                        this.voteList.push(voteobj)
                        if(data.vote_content){
                            this.component.show()
                        }
                    }


                })
            },
            submit() {
                // this.component.hide()
                var that = this
                const baseUrl = "https://api.xroom.net/v4"
                let anonymousVal = 0
                if(this.anonymous == false) {
                    anonymousVal = 1
                }
                var arrtmp = [];
                for(let i = 0; i < this.voteList.length; i++) {
                    let anonymousVal = 0;
                    if(this.voteList[i].anonymous == true) {
                        anonymousVal = 1
                    }
                    arrtmp.push({
                        vote_id:this.voteList[i].vote_id,
                        vote_option_id:this.voteList[i].vote_option_id,
                        anonymous:anonymousVal,
                        remark:this.voteList[i].remark
                    })
                }
                for(let i = 0 ; i < arrtmp.length; i++) {
                    if(arrtmp[i].vote_option_id == '') {
                        that.toast = that.$createToast({
                            txt: '选项不能为空，请选择',
                            type: 'txt'
                        })
                        that.toast.show()
                        return
                    }
                }
                var voteparams= {
                    activity_id:this.activity_id,
                    user_id:this.userInfo.uid,
                    vote_list:arrtmp
                }
                console.log("voteparams", voteparams)
                this.$axios.post('/api/v4/vote/record/batch', voteparams)
                    .then(function (response) {
                        if(response.data.errcode === 200) {
                            that.toast = that.$createToast({
                                txt: '投票成功',
                                type: 'txt'
                            })
                            that.toast.show()
                        }else{
                            that.toast = that.$createToast({
                                txt: response.data.errmsg,
                                type: 'txt'
                            })
                            that.toast.show()
                        }

                        that.$emit("clearVoteId")
                        setTimeout(() =>{
                            that.component.hide()
                            },800)

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }

    }
</script>

<style scoped>
    .title{
        font-size: 18px;
        color:#797979;
    }
    .submit .el-button{
        width:100%;
    }
</style>

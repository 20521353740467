import Vue from 'vue'
import Vuex from 'vuex'
import create from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  appid: process.env.VUE_APP_APPID,
	  // projectUrl: process.env.VUE_APP_DOMAIN + '/' + process.env.VUE_APP_APPID + '/client_center/index.html#',  //当前项目地址
	  // keeperUrl: process.env.VUE_APP_DOMAIN + '/' + process.env.VUE_APP_APPID + '/policy_keeper/index.html#',  //保管管家地址
	  // ucUrl: process.env.VUE_APP_UC + '/view/myProfile/myProfile.html',  //UC地址
	  loading: false,  //loading动画  白色背景
	  onlyLoading: false,  //loading动画  透明背景
    currentMessageList:[],
    userInfo:{
    },
    APPInfo:{
      usersig:'',
      uid:'',
      SDKAppID: process.env.VUE_APP_APPID,
    },

    pageInfo:{
      // groupID: '@TGS#aW6PMLGHV',
      groupID: '1619723976',
      // groupType:TIM.TYPES.GRP_MEETING,  //'TIM.TYPES.GRP_AVCHATROOM'
      // url: "webrtc://5664.liveplay.myqcloud.com/live/5664_harchar1?txSecret=f22a813b284137ed10d3259a7b5c224b&txTime=6403f7bb",
      url: "webrtc://play.xroom.net/live/B23477-1-main-426630",
    },
    
  },
  mutations: {
    /**
     * 将消息插入当前会话列表
     * 调用时机：收/发消息事件触发时
     * @param {Object} state
     * @param {Message[]|Message} data
     * @returns
     */
     pushCurrentMessageList(state, data) {
      if (Array.isArray(data)) {
        state.currentMessageList = [...state.currentMessageList, ...data]
      } else {
        state.currentMessageList = [...state.currentMessageList, data]
      }
    },

    userInfo(state, data){
      state.userInfo ={...state.userInfo, ...data};
    },
    APPInfo(state, data){
      state.APPInfo = {...state.APPInfo, ...data};
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [create({ storage: window.localStorage })]
})

<template>
	<div class="login">
        <!-- <cube-form
  :model="model"
  :schema="schema"
  :immediate-validate="false"
  :options="options"
  @validate="validateHandler"
  @submit="submitHandler"
  ></cube-form> -->
    <h1 class="titleText">账号密码登录</h1>
    <div class="item">
      <div class="iphone">

          <cube-select
                  class="selcode"
                  style="width:60px;color:#fff;"
              v-model="code_value"
              :options="code_options">
          </cube-select>
          <cube-input v-model="model.loginid" placeholder="请输入手机号" class="tel" style="width:60vw;background-color:#223B42;border:none;outline:none;color:#fff;"></cube-input>
<!--        <select name="" id="codesel">-->
<!--          <option value="+86"></option>-->
<!--        </select> <input type="text" placeholder="请输入手机号">-->
      </div>
      <div class="iphone2">
          <p style="width:50px;height:37px;line-height: 37px;color:#fff;padding-left:10px;">密码</p>
          <cube-input v-model="model.password" placeholder="请输入密码" type="password" style="width:60vw;background-color:#223B42;border:none;outline:none;"></cube-input>
      </div>
        <cube-button style="margin-top:30px;background-color:#1C8EBF;border-right: 50px;" @click="submitHandler">登录</cube-button>
        <div class="foot">
           <p class="p1">北京新界教育科技有限公司</p>
           <p class="p2">@Copyright1999-2020 xroom.net All Right Reserved</p>
        </div>
    </div>
    </div>
</template>

<script>
import {cookieUtil, getQueryString} from '../../until/until'
export default {
	name:'login',
     data() {
    return {
      validity: {},
      valid: undefined,
        code_value:'86',
        code_options:['86','886','852','853'],
        tel:'',
        pwd:'',
      model: {
        client_id: 'ndmooc_h5',
        // loginid: '13801223024',
        loginid: '',
        response_type: 'token',
        // password: 'ndmooc01',
        password: '',

      },
      schema: {
        groups: [
          {
            fields: [
              {
                type: 'input',
                modelKey: 'loginid',
                label: '手机号',
                props: {
                  placeholder: '请输入'
                },
                rules: {
                  required: true
                },
                // validating when blur
                trigger: 'blur'
              },
            {
                type: 'input',
                modelKey: 'password',
                label: '密码',
                props: {
                  placeholder: '请输入'
                },
                rules: {
                  required: true
                },
                // validating when blur
                trigger: 'blur'
              },
            ]
          },
           {
            fields: [
              {
                type: 'submit',
                label: '登 录'
              },

            ]
          }
        ]
      },
      options: {
        scrollToInvalidField: true,
        layout: 'standard' // classic fresh
      }
    }
  },
  methods: {
    async submitHandler(e) {
      // e.preventDefault()
      if(!this.model['loginid'] ){
        this.toast = this.$createToast({
                                txt: '请输入手机号',
                                type: 'txt'
                            })
                            this.toast.show()
        return
      }
      if( !this.model['password']){
        this.toast = this.$createToast({
                                txt: '请输入密码',
                                type: 'txt'
                            })
                            this.toast.show()
        return
      }
      let param = new FormData();
      param.append('client_id', this.model['client_id'])
      param.append('loginid', this.model['loginid'])
      param.append('response_type', this.model['response_type'])
      param.append('password', this.model['password'])
      let res = await this.$api.login(param);
      if(res.errcode === 0){
        //    设置登录信息
          cookieUtil.setCookie('token',res.data.access_token,10);
          localStorage.setItem('token',res.data.access_token);
          localStorage.setItem('userId',res.data.uid)
          this.$store.commit('userInfo',res.data)
          let usersig = await this.$api.usersig({userid: res.data.uid})
          if(usersig.errcode === 0){
              this.$store.commit('APPInfo',usersig.data);
              console.log(getQueryString('activity_id'))
              this.$router.push({path:'/',query:{activity_id: getQueryString('activity_id')}})
          }else{
            this.toast = this.$createToast({
                                txt: res.errmsg,
                                type: 'txt'
                            })
                            this.toast.show()
          }
      }else{
            this.toast = this.$createToast({
                                txt: res.errmsg,
                                type: 'txt'
                            })
                            this.toast.show()
          }
    },
    validateHandler(result) {
      this.validity = result.validity
      this.valid = result.valid
      console.log('validity', result.validity, result.valid, result.dirty, result.firstInvalidFieldIndex)
    },
    resetHandler(e) {
      console.log('reset', e)
    }
  }
}
</script>

<style scoped lang="less">
input::-webkit-input-placeholder{
    color:#fff;
}
input::-moz-placeholder{   /* Mozilla Firefox 19+ */
    color:#fff;
}
input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
    color:#fff;
}
input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
    color:#fff;
}
.titleText{
    position: absolute;
    top:20vh;
    color: #fff;
    font-size: 16px;
    width:100%;
    text-align: center;
    height:60px;
    line-height: 60px;
}
.item{
    height:30vh;
}
.login{
    height: 100vh;
  background: #223B42;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
    .iphone{
        display: flex;
        justify-content: flex-start;
    }
    .iphone2{
        display: flex;
        justify-content: flex-start;
        margin-top:10px;
    }
    .cube-select{
        background-color:#223B42;
        color:#fff;

    }
    .cube-select::after{
        border:none;
    }
    /deep/.cube-select > span{
        color:#fff;
    }
    /deep/select{
        color:#fff !important;
    }
    /deep/.cube-input-field{
        color:#fff !important;
        border-bottom: 1px solid #fff
    }
    /deep/input{
        color:#ffffff !important;
    }
    /deep/.cube-input::after{
        border:none;
    }
    .foot{
        position: absolute;
        bottom:10px;
    }
    .foot .p1{
        width:300px;
        font-size: 14px;
        color: #bdbdbd;
        text-align: center;
    }
    .foot .p2{
        width:300px;
        margin-top:5px;
        font-size: 10px;
        color:#bdbdbd;
        text-align: center;
    }
</style>
